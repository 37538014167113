<template>
    <KCourse loader-class="MBcont" course-id="8" title="Будуарная кукла" :items="items">
        <div class="MBtextcont">
            <p>Сегодня доделаем шарики и слепим "базу" лица )</p>
            <h3 class="nomargin">Необходимые материалы:</h3>           
            <ul>
                <li>Тканная перчатка вашего размера (чтобы пальцы были туго обтянуты, а не висели мешки ткани)</li>
                <li>Шаблон для квиллинговых форм</li>
                <li>Линейка с круглыми отверстиями</li>
                <li>Стеки</li>
                <li>Надфили</li>
                <li>Циркуль, прозрачная линейка с разметкой</li>
            </ul>
            <h3>1. Соединяем сферу, шкурим полусферы</h3>
        </div>

        <VideoView video-id="c386b3467fe5440ca6d11298b30ff195"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Шкурим срезы шариков так, чтобы при соединении они образовывали шар и легко входили в форму для квиллинга, оставляя зазор в 1мм</li>
                <li>Прокладываем соединительный слой пластика, соединяем, проверяем, чтобы шарик легко входил в квиллинговую форму</li>
                <li>У оставшихся полусфер сошкуриваем срез и юбочку (либо с помощью шкурки, либо с помощью линейки)</li>
            </ol>
            
            <h3>2. Лепим основные объемы на лице</h3>
        </div>

        <VideoView video-id="b1d49499a0d0447d8e712d98d93fce4f"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Наращиваем объем на лбу</li>
                <li>Наращиваем объем на переносице</li>
                <li>Долепливаем носик, задаем направление для ноздрей и намечаем отверстия</li>
                <li>Лепим губы, верхняя в форме козырька, нижняя в форме капли</li>
                <li>Добавляем подбородок в виде шарика</li>
                <li>Если нужно, наращиваем верхнее веко</li>
            </ol>

            <a class="checklist" :class="$mq" href="/files/BodoirV2/HeadBase1.pdf" target="_blank">
                <div class="checklist-img" :class="$mq">
                    <div class="checklist-icon">
                        <KImage :src="require('@/assets/membersh/bodoirV2/checklist-button-facebase.png')" alt="" width="100%"/>
                    </div>
                </div>
                <div class="checklist-text" :class="$mq">
                    <h3>Скачать чек-лист в картинках</h3>
                    <p>Его можно распечатать, идти четко по пунткам и тем самым разобрать свою заготовку</p>
                </div>
            </a>

            <h3>3. Шкурим готовые сферы</h3>
        </div>

        <VideoView video-id="79fca4a364294f6289f976e9034a425b"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Одеваем тканные перчатки</li>
                <li>Бросаем шарики в воду на 1-2 сек</li>
                <li>Достаем, даем верхнему слою размякнуть 1-2 минуты</li>
                <li>Берем линейку, ищем отверстие в которое шарик не провалится, и начинаем прокручивать</li>
                <li>Повторяем этот процесс еще раз.</li>
            </ol>

        </div>

        <div class="MBtextcont">
            <h3 class="nomargin">Итого, что нужно сделать:</h3>
            <ol>
                <li>Вышкурить шарики</li>
                <li>Слепить базовые объемы на лице</li>
            </ol>
           <h3>Время выполнения: 3 часа</h3>
        </div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";
    import chapters from './data.js';
    import KImage from "../../Widgets/KImage";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
            KImage
        },
        data() {
        return {
            items: chapters
        }
        },
        methods: {
            showImage(src, customClass) {
                this.$swal.fire({
                    customClass: customClass,
                    background: '#ffffff',
                    html: '<img width="100%" src="'+src+'"/>',
                    showCloseButton: true,
                    showConfirmButton: false,
                    // closeButtonHtml: "",
                })
            },
        }
    }
</script>

<style>

</style>